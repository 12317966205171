var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 问题列表 ")]},proxy:true},{key:"input",fn:function(){return [_c('span',{staticClass:"tag"},[_vm._v("发布时间: ")]),_c('a-range-picker',{attrs:{"show-time":{ defaultValue: _vm.moment('00:00:00', 'HH:mm:ss') },"format":"YYYY-MM-DD HH:mm:ss"},on:{"change":_vm.onChange},model:{value:(_vm.Time),callback:function ($$v) {_vm.Time=$$v},expression:"Time"}}),_c('a-input',{attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('a-button',{staticClass:"btn",attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.search()}}},[_vm._v("搜索")]),_c('a-button',{staticClass:"all_boder_btn btn",on:{"click":function($event){return _vm.toDetail(1, _vm.userType)}}},[_vm._v("新建")]),_c('a-upload',{attrs:{"showUploadList":false,"name":"file","customRequest":_vm.fileAction}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("导入基础信息")])],1),_c('a',{staticClass:"left",attrs:{"href":_vm.$config.target + '/hxclass-management/information/problem/template',"target":"_blank","rel":"noopener noreferrer"}},[_c('a-button',{staticStyle:{"margin-right":"10px"}},[_vm._v("下载导入模板")])],1)]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-tabs',{attrs:{"default-active-key":"1"},on:{"change":_vm.callback}},_vm._l((_vm.Tab),function(item){return _c('a-tab-pane',{key:item.cpId,attrs:{"tab":item.categoryName}})}),1)],1),_c('a-table',{staticClass:"table-template",attrs:{"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{ emptyText: '暂无数据' },"pagination":{
      total: _vm.total,
      current: _vm.pageNumber,
      defaultPageSize: _vm.pageSize,
      showSizeChanger: true,
      showTotal: function (total, range) {
        return ("共" + total + "条");
      },
    }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + index + 1)+" ")]}},{key:"operation",fn:function(item, row, index){return [_c('span',{staticClass:"blueText"},[(!(_vm.pageNumber == 1 && index == 0))?_c('span',{on:{"click":function($event){return _vm.carry(row)}}},[_vm._v("上移 |")]):_vm._e(),((_vm.pageNumber - 1) * _vm.pageSize + index + 1 < _vm.total)?_c('span',{on:{"click":function($event){return _vm.abdication(row)}}},[_vm._v("下移 |")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.toDetail(2, row.qaId)}}},[_vm._v("编辑 |")]),_c('span',{on:{"click":function($event){return _vm.deleteData(row)}}},[_vm._v("删除")])])]}}])}),_c('LeadingIn',{ref:"leadingIn",attrs:{"columns":_vm.columnsss,"successList":_vm.successList,"failureList":_vm.failureList,"visible":_vm.importVisible,"handleCancel":_vm.modelhandleCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }