<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 问题列表 </template>
      <template #input>
        <span class="tag">发布时间: </span>
        <a-range-picker
          :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
          v-model="Time"
          format="YYYY-MM-DD HH:mm:ss"
          @change="onChange"
        />
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-button type="primary" class="btn" :loading="loading" @click="search()">搜索</a-button>
        <a-button class="all_boder_btn btn" @click="toDetail(1, userType)"
          >新建</a-button
        >
        <a-upload
          :showUploadList="false"
          name="file"
          :customRequest="fileAction"
        >
          <a-button type="primary">导入基础信息</a-button>
        </a-upload>
        <a
          :href="
            $config.target + '/hxclass-management/information/problem/template'
          "
          target="_blank"
          rel="noopener noreferrer"
          class="left"
        >
          <a-button style="margin-right: 10px">下载导入模板</a-button>
        </a>
      </template>
    </HeaderBox>

    <div class="table-box">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane
          v-for="item in Tab"
          :key="item.cpId"
          :tab="item.categoryName"
        ></a-tab-pane>
      </a-tabs>
    </div>
    <a-table
      class="table-template"
      :columns="columns"
      :loading="loading"
      :data-source="tableData"
      :locale="{ emptyText: '暂无数据' }"
      @change="onPage"
      :pagination="{
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>

      <template slot="operation" slot-scope="item, row, index">
        <span class="blueText">
          <span @click="carry(row)" v-if="!(pageNumber == 1 && index == 0)"
            >上移 |</span
          >
          <span
            @click="abdication(row)"
            v-if="(pageNumber - 1) * pageSize + index + 1 < total"
            >下移 |</span
          >
          <span @click="toDetail(2, row.qaId)">编辑 |</span>
          <span @click="deleteData(row)">删除</span>
        </span>
      </template>
    </a-table>

    <!-- 数据弹窗 -->
    <LeadingIn
      ref="leadingIn"
      :columns="columnsss"
      :successList="successList"
      :failureList="failureList"
      :visible="importVisible"
      :handleCancel="modelhandleCancel"
    />
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import moment from "moment";
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "标题",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "发布时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "浏览量",
    align: "center",
    dataIndex: "viewNum",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
const columnsss = [
  {
    title: "标题",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "回答",
    align: "center",
    dataIndex: "content",
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox, LeadingIn }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      loading:false,
      userType: 1,
      columns,
      tableData: [],
      total: 0,
      Time: null,
      endTime: null, //结束时间
      starTime: null, // 开始时间
      name: null,
      Tab: [],
      qaTypeId: 1, // 分类id
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      // 弹框
      columnsss,
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
    };
  }, // 事件处理器
  methods: {
    moment,
    modelhandleCancel() {
      this.importVisible = false;
    },
    onChange(date, dateString) {
      // console.log(date, dateString);
      if (dateString.length < 1) {
        this.endTime = null;
        this.starTime = null;
      } else {
        this.starTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    callback(e) {
      this.qaTypeId = e;
      this.getdataList();
    },
    toDetail(operation, id) {
      this.$router.push(
        "/admin/contentConfig/questionDetail?" +
          "operation=" +
          operation +
          "&id=" +
          id
      );
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      this.getdataList();
    },
    // 上移
    carry(e) {
      let previndex = "";
      this.tableData.map((item, index) => {
        if (item.qaId == e.qaId) {
          previndex = index - 1;
        }
      });
      // console.log(previndex);
      this.$ajax({
        url:
          "/hxclass-management/information/problem/up?id=" +
          e.qaId +
          "&oldId=" +
          this.tableData[previndex].qaId,
        method: "PUT",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.getdataList();
        }
      });
    },
    // 下移
    abdication(e) {
      let previndex = "";
      this.tableData.map((item, index) => {
        if (item.qaId == e.qaId) {
          previndex = index + 1;
        }
      });
      // console.log(previndex);
      this.$ajax({
        url:
          "/hxclass-management/information/problem/down?id=" +
          e.qaId +
          "&oldId=" +
          this.tableData[previndex].qaId,
        method: "PUT",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.getdataList();
        }
      });
    },
    // 导入基础信息
    fileAction(e) {
      this.$ajax({
        url: "/hxclass-management/information/problem/batch",
        method: "PUT",
        headers: [{ type: "file" }],
        params: {
          file: e.file,
          name: "file",
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.successList = res.data.success;
          this.failureList = res.data.fail;
          this.importVisible = true;
          // this.pageNumber = 1;
          // this.pageSize = 10;
          // this.getdataList();
        } else {
          this.$message.success(res.message);
        }
      });
    },

    // // 下载错误excel
    // downloadExcel() {
    //   if (!this.failureList.length) {
    //     return;
    //   }
    //   window.open(
    //     this.$config.target +
    //       "/hxclass-management/exam/certif/export/certifinfo?errorJson=" +
    //       encodeURIComponent(JSON.stringify(this.failureList)) +
    //       "&type=2"
    //   );
    // },
    downloadExcel() {
      if (!this.failureList.length) {
        return;
      }
      this.$ajax({
        url: "/hxclass-management/information/answer/export/answer/wrong",
        method: "post",
        headers: [{ type: "file" }],
        params: {
          errorJson: JSON.stringify(this.failureList),
        },
        responseType: "blob",
      }).then((res) => {
        this.blobDownload(res, "错误文件");
      });
    },

    // blob文件转excel
    blobDownload(data, name) {
      let m = this;
      var content = data;
      // var data = new Blob([content],{type:"application/octet-stream;charset=utf-8"});
      var data = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = name + ".xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
    },

    // 导入正确数据
    importSuccessList() {
      this.$ajax({
        url: "/hxclass-management/information/problem/import/insert",
        method: "POST",
        params: this.successList,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$refs.leadingIn.confirmLoading = false;
          this.$message.success(res.message);
          this.getdataList();
          this.importVisible = false;
        } else {
          this.importVisible = false;
          this.$message.error(res.message);
        }
      });
    },

    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/information/problem/list",
        method: "get",
        params: {
          endTime: this.endTime, // 结束时间
          starTime: this.starTime, // 开始时间
          qaTypeId: this.qaTypeId, //分类id
          key: this.name, //关键字
          page: this.pageNumber,
          size: this.pageSize,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },

    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 获取标签数据
    getTab() {
      this.$ajax({
        url: "/hxclass-management/information/problem/category/list",
        method: "get",
        params: {
          page: 1,
          size: 10000,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // console.log(res);
          this.Tab = res.data.records;
          this.qaTypeId = this.Tab[0].cpId;
          this.getdataList();
        }
      });
    },

    // 删除对象
    deleteData(item) {
      let _this = this;
      this.$confirm({
        title: "确定删除该标签吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/information/problem?id=" + item.qaId,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getdataList();
              }
            });
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getTab();
  },
  // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin: 0 14px 14px;
}
.all_main_wrap {
  min-height: 100%;

  .table-box {
    display: flex;
    justify-content: space-between;

    /deep/ .ant-tabs-bar {
      border-bottom: none;
    }

    /deep/.ant-tabs-bar {
      margin: 0;
    }

    .all_boder_btn {
      margin-top: 10px;
    }
  }
}
.left {
  margin-left: 16px;
}
</style>
